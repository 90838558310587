// 客户管理路由
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default [
    {
        path: "/custom",
        name: "custom",
        component: () => import("@/views/Home.vue"),
        meta: { title: "客户管理" },
        children: [
            {
                path: "/custom/customList",
                name: "customList",
                meta: { title: "客户列表", isShow: true },
                component: () => import("@/views/custom/customList/CustomList.vue"),
                children: [
                    {
                        path: "/custom/customDetail/:id",
                        name: "customDetail",
                        meta: { title: "客户详情", isShow: true },
                        component: () => import("@/views/custom/customList/CustomDetail.vue"),
                    },
                ]
            },
        ]
    }
]