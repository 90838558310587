// 招标路由
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default [
    {
        path: "/invite",
        name: "invite",
        component: () => import("@/views/Home.vue"),
        meta: { title: "招标管理" },
        children: [
            {
                path: "/invite/inviteList",
                name: "inviteList",
                meta: { title: "招采大厅", isShow: true },
                component: () => import("@/views/invite/inviteList/InviteList.vue"),
                children: [
                    {
                        path: "/invite/quoteDetail",
                        name: "quoteDetail",
                        meta: { title: "报价详情" },
                        component: () => import("@/views/invite/quoteDetail/QuoteDetail.vue"),
                    },
                    {
                        path: "/invite/publicity",
                        name: "publicity",
                        meta: { title: "公示" },
                        component: () => import("@/views/invite/publicity/publicity.vue"),
                    },
                    {
                        path: "/invite/inquiry",
                        name: "inquiry",
                        meta: { title: "报价详情" },
                        component: () => import("@/views/invite/inquiry/inquiry.vue"),
                    },
                ]
            },
            {
                path: "/invite/myInviteList",
                name: "myInviteList",
                meta: { title: "我的报价", isShow: true },
                component: () => import("@/views/invite/myInviteList/myInviteList.vue"),
                children: [
                    {
                        path: "/myInviteList/quoteDetail",
                        name: "myQuoteDetail",
                        meta: { title: "报价详情" },
                        component: () => import("@/views/invite/myInviteList/QuoteDetail.vue"),
                    },
                    {
                        path: "/myInviteList/inquiry",
                        name: "myInquiry",
                        meta: { title: "报价详情" },
                        component: () => import("@/views/invite/myInviteList/inquiry.vue"),
                    },
                ]
            },
        ]
    }
]