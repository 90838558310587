import Vue from 'vue'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import "reset-css"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/css/global.css"
import App from './App.vue';
import router from './router';
import store from './store';
import dayjs from "dayjs";


Vue.config.productionTip = false
import MPagination from "@/components/global/MPagination.vue";
Vue.component('MPagination', MPagination);  // 分页组件
Vue.use(ElementUI, { zIndex: 1000 });

Vue.prototype.$dayJs = dayjs;
Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    'inline': true, // 是否启用inline模式
    'button': true, // 是否显示右上角关闭按钮
    'navbar': true, // 是否显示缩略图底部导航栏
    'title': true, // 是否显示当前图片标题，默认显示alt属性内容和尺寸
    'toolbar': true, // 是否显示工具栏
    'tooltip': true, // 放大或缩小图片时，是否显示缩放百分比，默认true
    'fullscreen': true, // 播放时是否全屏，默认true
    'loading': true, // 加载图片时是否显示loading图标，默认true
    'loop': true, // 是否可以循环查看图片，默认true
    'movable': true, // 是否可以拖得图片，默认true
    'zoomable': true, // 是否可以缩放图片，默认true
    'rotatable': true, // 是否可以旋转图片，默认true
    'scalable': true, // 是否可以翻转图片，默认true
    'toggleOnDblclick': true, // 放大或缩小图片时，是否可以双击还原，默认true
    'transition': true, // 使用 CSS3 过度，默认true
    'keyboard': true, // 是否支持键盘，默认true
    'zoomRatio': 0.1, // 鼠标滚动时的缩放比例，默认0.1
    'minZoomRatio': 0.01, // 最小缩放比例，默认0.01
    'maxZoomRatio': 100, // 最大缩放比例，默认100
    'url': 'data-source' // 设置大图片的 url
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
