// 分销管理路由
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default [
    {
        path: "/marketing",
        name: "marketing",
        component: () => import("@/views/Home.vue"),
        meta: { title: "营销活动" },
        children: [
            {
                path: "/marketing/distribution",
                name: "distribution",
                meta: { title: "分销管理", isShow: true },
                component: () => import("@/views/marketing/distribution/Distribution.vue"),
            },
            {
                path: "/marketing/distriMarket",
                name: "distriMarket",
                meta: { title: "分销市场", isShow: true },
                component: () => import("@/views/marketing/distriMarket/DistriMarket.vue"),
                children:[
                    {
                        path: "/marketing/marketDetail/:id",
                        name: "marketDetail",
                        meta: { title: "商品详情",},
                        component: () => import("@/views/marketing/distriMarket/MarketDetail.vue"),
                    },
                    {
                        path: "/marketing/markGoodsDetail/:id",
                        name: "markGoodsDetail",
                        meta: { title: "分销商品详情",},
                        component: () => import("@/views/marketing/distriMarket/MarkGoodsDetail.vue"),
                    },
                ]
            },
        ]
    }
]