// 库存管理路由
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default [
    {
        path: "/inventory",
        name: "inventory",
        component: () => import("@/views/Home.vue"),
        meta: { title: "库存管理" },
        children: [
            {
                path: "/inventory/inventoryCenter",
                name: "inventoryCenter",
                meta: { title: "库存中心", isShow: true },
                component: () => import("@/views/inventory/inventoryCenter/InventoryCenter.vue"),
            },
            {
                path: "/inventory/inventoryDetail",
                name: "inventoryDetail",
                meta: { title: "库存明细", isShow: true },
                component: () => import("@/views/inventory/inventoryDetail/InventoryDetail.vue"),
            },
            {
                path: "/inventory/inventoryStorage",
                name: "inventoryStorage",
                meta: { title: "出入库", isShow: true },
                component: () => import("@/views/inventory/inventoryStorage/inventoryStorage.vue"),
                children: [
                    {
                        path: "/inventory/inOutboundAdd",
                        name: "inOutboundAdd",
                        meta: { title: "新增出入库" },
                        component: () => import("@/views/inventory/inventoryStorage/inOutboundAdd.vue"),
                    },
                    {
                        path: "/inventory/inOutboundDetail/:id",
                        name: "inOutboundDetail",
                        meta: { title: "出入库详情" },
                        component: () => import("@/views/inventory/inventoryStorage/inOutboundDetail.vue"),
                    },
                ]
                
            },
            {
                path: "/inventory/inventoryCheck",
                name: "inventoryCheck",
                meta: { title: "库存盘点", isShow: true },
                component: () => import("@/views/inventory/inventoryCheck/inventoryCheck.vue"),
                children: [
                    {
                        path: "/inventory/inventoryCheckAdd",
                        name: "inventoryCheckAdd",
                        meta: { title: "新增盘点" },
                        component: () => import("@/views/inventory/inventoryCheck/inventoryCheckAdd.vue"),
                    },
                    {
                        path: "/inventory/inventoryCheckDetail/:id",
                        name: "inventoryCheckDetail",
                        meta: { title: "盘点详情" },
                        component: () => import("@/views/inventory/inventoryCheck/inventoryCheckDetail.vue"),
                    },
                ]
                
            },
        ]
    }
]