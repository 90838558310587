// 消息管理路由
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default [
    {
        path: "/system",
        name: "system",
        component: () => import("@/views/Home.vue"),
        meta: { title: "店铺管理" },
        children: [
            {
                path: "/system/address",
                name: "address",
                meta: { title: "地址管理", isShow: true },
                component: () => import("@/views/system/address/Address.vue"),
                children: [
                    {
                        path: "/system/updAddress/:id",
                        name: "updAddress",
                        meta: { title: "编辑地址" },
                        component: () => import("@/views/system/address/UpdAddress.vue"),
                    },
                ]
            },
            {
                path: "/system/message",
                name: "message",
                meta: { title: "平台公告", isShow: true },
                component: () => import("@/views/system/message/Message.vue"),

            },
            {
                path: "/system/information",
                name: "information",
                meta: { title: "店铺资料", isShow: true },
                component: () => import("@/views/system/information/Information.vue"),
                children: [
                    {
                        path: "/system/editInformation",
                        name: "editInformation",
                        meta: { title: "编辑信息" },
                        component: () => import("@/views/system/information/editInformation.vue"),
                    },
                ]
            },
            {
                path: "/system/invoice",
                name: "invoice",
                meta: { title: "发票管理", isShow: true },
                component: () => import("@/views/system/invoice/Invoice.vue"),
                children: [
                    {
                        path: "/system/editInvoice",
                        name: "editInformation",
                        meta: { title: "修改发票" },
                        component: () => import("@/views/system/invoice/InvoiceEdit.vue"),
                    },
                    {
                        path: "/system/addInvoice",
                        name: "addInformation",
                        meta: { title: "添加发票" },
                        component: () => import("@/views/system/invoice/InvoiceAdd.vue"),
                    },
                ]
            },
            {
                path: "/system/shopDecoration",
                name: "shopDecoration",
                meta: { title: "店铺装修", isShow: true },
                component: () => import("@/views/system/shopDecoration/ShopDecoration.vue"),
            },
            {
                path: "/system/templateEdit",
                name: "templateEdit",
                meta: { title: "模版编辑", isShow: true },
                component: () => import("@/views/system/shopDecoration/TemplateEdit.vue"),
            },
            {
                path: "/system/stencilMarket",
                name: "stencilMarket",
                meta: { title: "模版市场", isShow: true },
                component: () => import("@/views/system/stencilMarket/StencilMarket.vue"),
            },
            {
                path: "/system/previewTpl",
                name: "previewTpl",
                meta: { title: "模版预览", isShow: true },
                component: () => import("@/views/system/stencilMarket/PreviewTpl.vue"),
            },
        ]
    }
]